import { useRouter } from "next/router";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../styles/styles.scss";
import SourceFlowHead from "@sourceflow-uk/sourceflow-head";
import metaObject from "../.sourceflow/metadata.json";

import Layout from "../components/layout";

function MyApp({ Component, pageProps }) {
  const router = useRouter();

  // Exclude `/components` page from being wrapped in Layout and SourceFlowHead
  const isComponentsPage = router.pathname === "/components";

  return isComponentsPage ? (
    <>
      <Component {...pageProps} />
    </>
  ) : (
    <Layout>
      <SourceFlowHead metaObject={metaObject} addTracker={true} />
      <Component {...pageProps} />
    </Layout>
  );
}

export default MyApp;
